import { connect } from "react-redux";
import {doGetTemplateWithFile, doSaveTemplate, doVerifyPDF} from "../actions/thunks/templates";
import { DispatchProps, StateProps, InnerProps, TemplateArea } from "../components/TemplateArea";
import { RootState, ThunkDispatch } from "../types";
import { NewTemplate } from "../types/templates";
import {initialize, reset} from "../actions/pdfdoped";


function mapStateToProps({ template, pdfdoped }: RootState): StateProps {
    return {
        template: template.template,
        templateFile: template.curFile,
        fields: pdfdoped.fields,
        pageMatrices: pdfdoped.pageMatrices,
        file: pdfdoped.file,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetTemplateWithFile: (templateId: string) => dispatch(doGetTemplateWithFile(templateId)),
        onSaveTemplate: (newTemplate: NewTemplate) => dispatch(doSaveTemplate(newTemplate)),
        onReset: () => dispatch(reset()),
        onInitialize: (file, fields) => dispatch(initialize(file, fields)),
        onVerifyPDF: (file) => dispatch(doVerifyPDF(file)),
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplateArea);
