import {TemplateFieldType} from "../types/pdfdoped";

const it = {
    locale: {
        en: "EN",
        it: "IT",
    },
    input: {
        copy: "Copia",
        copied: "Copiato"
    },
    toolbar: {
        campaigns: "Campagne",
        templates: "Modelli",
        audiences: "Pubblici"
    },
    user: {
        profile: "Profilo utente",
        account: "Account",
        logout: "Disconnetti",
        login: "Accedi",
        support: "Supporto",
        name: "Nome",
        email: "Email",
        verified: "Email verificata",
        modify: "Modifica",
        save: "Salva modifiche",
        resetPasswordMessage: "Un'email è stata inviata al tuo indirizzo con le istruzioni per cambiare la password",
        resendEmail: "invia nuovamente",
        resendEmailMessage: "Un'email è stata inviata al tuo indirizzo con le istruzioni per la verifica dell'account. Dopo aver verificato il tuo account, ricarica questa pagina",
    },
    alerts: {
        infos: {
            newTemplate: "Modello salvato correttamente",
            deleteTemplates: "Modelli eliminati correttamente",
            deleteAudiences: "Gruppi eliminati correttamente",
            newCampaign: "Campagna salvata correttamente",
            deleteCampaigns: "Campagne eliminate correttamente",
            deleteSigners: "Firmatari eliminati correttamente",
            emailReSent: "Email rinviata correttamente",
            addedCollaborator: "Collaboratore invitato correttamente",
            removedCollaborator: "Collaboratore rimosso correttamente",
            changeExpirationDate: "Data di scadenza modificata correttamente"
        },
        errors: {
            newTemplateMissing: "Seleziona un modello pdf con almeno un campo firma",
            newTemplate: "Errore nella creazione del template",
            deleteTemplates: "Errore durante l'eliminazione dei modelli",
            deleteAudiences: "Errore durante l'eliminazione dei pubblici",
            newCampaign: "Errore durante la creazione della campagna",
            deleteCampaigns: "Errore durante l'eliminazione delle campagne",
            deleteSigners: "Errore durante l'eliminazione dei firmatari",
            pdfDownload: "Errore nello scaricamento del PDF",
            xmlDownload: "Errore nello scaricamento del file di prova XML",
            emailReSend: "Errore nel rinvio dell'email",
            addingCollaborator: "Errore durante l'invito al collaboratore",
            removingCollaborator: "Errore durante la rimozione del collaboratore",
            noCredits: "Errore! Non è possibile firmare, pagamento richiesto da parte del creatore della campagna",
            campaignNotFound: "Errore! Campagna non trovata",
            expiredCampaign: "Errore! Campagna scaduta",
            noSignatureClosedCampaign: "Errore! Firma relativa alla campagna chiusa non trovata",
            genericError: "Errore del server, riprova più tardi",
            changeExpirationDate: "Errore nella modifica della data di scadenza",
            signatureSameEmail: "Errore! E' stata già effettuata una firma in questa campagna con l'email specificata. Utilizza una email diversa o contatta il responsabile della campagna."
        }
    },
    generic: {
        yes: "Sì",
        no: "No",
    },
    error: {
        page: "Errore",
        forbidden: "Accesso negato",
        notFound: "Pagina non trovata",
        notAuthenticated: "Utente non autenticato",
    },
    kptable: {
        filters: "Filtri",
        apply: "Applica",
        reset: "Resetta",
        show_columns: "Mostra colonne",
        pager: {
            first: "Prima",
            last: "Ultima",
        },
        actions: "Azioni",
        date: {
            short: "DD/MM/YYYY",
            long: "DD/MM/YYYY HH:mm",
        },
        confirm: {
            title: "Conferma",
            yes: "Sì",
            no: "No",
        },
        show: "Mostra",
        globalSelectionWarn1: "Sono stati selezionati tutti gli elementi di questa pagina .",
        globalSelectionWarn2: "Tutti gli elementi sono stati selezionati. ",
        globalSelectionWarnAll: "Seleziona tutti gli elementi",
        globalSelectionWarnNotAll: "Cancella la selezione",
        globalSelectionFilterWarn1: "(Tutti i filtri attivi saranno considerati nella selezione)",
        globalSelectionFilterWarn2: "(Tutti i filtri attivi sono considerati nella selezione)",
        genericFilterError: "Devi aggiungere un filtro o modificare uno esistente",
    },
    credits: {
        overview: "Panoramica",
        remaining: "Firme rimanenti",
        extra: "Firme extra consumate",
        buy: "Acquista crediti"
    },
    stats: {
        active: "Campagne attive",
        rate: "Tasso di firma globale"
    },
    collaborators: {
        title: "Collaboratori",
        inviteLabel: "Invita a collaborare",
        inviteButton: "Invita",
        inviteTip: "Usa la virgola ',' per aggiungere più indirizzi email",
        invitePlaceholder: "Esempio vittoria@kopjra.com,lucio@legaltechitaly.com...",
        requiredEmails: "Inserisci almeno un indirizzo email",
        accepting: "Accettando l'invito a collaborare...",
        accepted: "Invito a collaborare accettato. Verrai rindirizzato tra 3 secondi...",
        errorAccepting: "Errore nella accettazione dell'invito. Contatta il responsabile della campagna.",
        owner: "Account principale",
        table: {
            email: "Email",
            status: "Status",
            delete: "Elimina",
            resend: "Rinvia",
        }
    },
    campaigns: {
        title: "Campagne",
        campaign: "Campagna",
        creating: "Creazione della campagna...",
        createError: "Errore nella creazione della campagna",
        addCampaign: "Nuova campagna",
        rate: "Tasso di firma",
        table: {
            noCampaigns: "Nessuna campagna creata!",
            open: "Apri",
            deleteMessage: "Vuoi davvero eliminare le campagne selezionate?",
            delete: "Elimina",
            labels: {
                name: "Nome",
                type: "Tipo",
                expired: "Scadenza",
                expiredNo: "No",
                status: "Stato",
                created: "Data di creazione",
                signed: "Firme",
                required2fa: "2fa Richiesta",
                publicLink: "Link Pubblico",
                customLogo: "Logo personalizzato"
            }
        },
        status: {
            NEW: "Nuova",
            ACTIVE: "Attiva",
            PAUSED: "In Pausa",
            FINISHED: "Terminata",
            EXPIRED: "Scaduta"
        },
        type: {
            OPEN: "Aperta",
            CLOSED: "Chiusa",
            MULTI: "Firma in sequenza",
        },
        creationBack: "Vuoi davvero tornare alla schermata precedente? Tutte le modifiche andranno perse",
        atLeastOneSignField: "Attenzione! Inserire almeno un campo firma nel template/documento"
    },
    templates: {
        title: "Modelli",
        new: "Nuovo modello",
        edit: "Modifica Modello",
        addTemplate: "Aggiungi modello",
        choose: "Scegli modello",
        upload: "Carica pdf",
        pdfErrorRequired: "Carica un file pdf",
        pdfErrorSize: "Carica un file pdf al più di 10MB",
        pdfVerifyError: "Il file PDF caricato risulta corrotto, si prega di sostituirlo con un file integro.",
        savedTemplate: "Da modello salvato",
        newDocument: "Nuovo documento",
        saveNewTemplate: "Salva come nuovo modello",
        filename: "Nome file",
        editOnTheFly: "Modifica",
        addField: "Campo",
        table: {
            open: "Apri",
            delete: "Elimina",
            deleteMessage: "Vuoi davvero eliminare i modelli selezionati?",
            add: "Nuovo modello",
            labels: {
                name: "Nome",
                pdf: "Pdf",
                created: "Data di creazione"
            }
        },
        fields: {
            field: "Campo",
            type: "Tipo",
            name: "Nome",
            description: "Testo",
            required: "Obbligatorio",
            nameError: "Caratteri speciali ammessi solo spazio, trattino e parentesi tonde (lunghezza minima 3 caratteri)",
            descriptionError: "Lunghezza minima 3 caratteri",
            select: "Seleziona",
            unselect: "Deseleziona",
            delete: "Elimina",
            signatureType: "Firma",
            conditionalSignatureType: "Firma condizionale",
            customType: "Personalizzato",
            emailType: "Email",
            fullnameType: "Nome completo",
            phoneType: "Numero di telefono con prefisso internazionale",
            dateType: "Data"
        }
    },
    audiences: {
        audience: "Pubblico",
        title: "Pubblici",
        info: {
            placeholders: {
                name: "Inserisci il nome del pubblico",
            },
            errors: {
                name: "Inserisci il nome del pubblico",
            }
        },
        table: {
            open: "Apri",
            delete: "Elimina",
            deleteMessage: "Vuoi davvero eliminare i pubblici selezionati?",
            add: "Nuovo pubblico",
            labels: {
                name: "Nome",
                nSigners: "Numero firmatari",
                createdAt: "Data di creazione"
            }
        },
        signers: {
            table: {
                add: "Aggiungi firmatari",
                delete: "Elimina",
                labels: {
                    fullName: "Nome completo",
                    email: "Email",
                    phoneNumber: "Numero di telefono",
                    createdAt: "Data di inserimento"
                },
                insertMessage: "Firmatari aggiunti correttamente al pubblico!",
                deleteMessage: "Vuoi davvero eliminare i firmatari selezionati dal pubblico?",
                insertErrorMessage: "Errore durante l'inserimento di uno o più firmatari, non sono ammesse email duplicate",
                confirmMessage: "I firmatari inseriti verranno aggiunti al pubblico, continuare?"
            },
        }
    },
    signs: {
        close: "Chiudi",
        resume: "Riprendi",
        pause: "Pausa",
        table: {
            add: "Aggiungi firmatari",
            delete: "Elimina",
            resend: "Rinvia",
            pdf: "PDF",
            xml: "XML",
            export: "Dati",
            exportPDFs: "PDF",
            exportXMLs: "XML",
            labels: {
                fullname: "Nome completo",
                email: "Email",
                phone: "Numero di telefono",
                status: "Stato",
                emailLastOpenedAt: "Ultima lettura",
            },
            insertMessage: "Firmatari aggiunti correttamente! Le email verranno inviate direttamente se la campagna è attiva o non appena tornerà attiva",
            deleteMessage: "Vuoi davvero eliminare i firmatari selezionati?",
            exportMessage: "Vuoi davvero scaricare tutti i dati relativi alle firme selezionate in un file excel? (Verranno considerati eventuali filtri attivi)",
            exportMessagePDFs: "Vuoi davvero scaricare tutti i PDF alle firme selezionate in un file zip? (Verranno considerati eventuali filtri attivi)",
            exportMessageXMLs: "Vuoi davvero scaricare tutti gli XML alle firme selezionate in un file zip? (Verranno considerati eventuali filtri attivi)"
        },
        errors: {
            invalidCode: "Codice campagna non valido",
            invalidCampaignId: "Id campagna non valido",
            campaignFinished: "Campagna terminata",
            campaignPaused: "Campagna in pausa",
            invalidSign: "Codice firma non valido",
            alreadySigned: "Hai già firmato questa campagna",
            noSignEnv: "Impossibile accedere all'ambiente di firma",
            insertErrorMessage: "Errore durante l'inserimento di uno o più firmatari, non sono ammesse email duplicate",
        },
        messages: {
            dataOk: "Dati firmatario inseriti correttamente!",
            checkEmail: "Una email con le istruzioni per firmare il documento è stata inviata all’indirizzo ",
            checkSpam: "Se non trovi l'email, controlla la cartella degli spam o il cestino.",
            emailHint: "Verifica l'indirizzo email inserito! Il link di firma non arriverà se l'email non è valida.",
        },
        proceed: "Procedi",
        labels: {
            title: "Firma campagna",
            fullname: "Nome completo",
            email: "Email",
            phone: "Numero di telefono con prefisso internazionale"
        },
        placeholders: {
            fullname: "Inserisci il tuo nome completo",
            email: "Inserisci la tua email",
            phone: "Inserisci il tuo numero di telefono con prefisso internazionale"
        },
        status: {
            NEW: "Nuova",
            SENT: "Inviata",
            NOT_SENT: "Errore invio",
            SIGNED: "Firmata"
        }
    },
    campaignCreation: {
        campaignInfo: "Nuova Campagna",
        document: "Documento",
        legalTerms: "Termini Legali",
        audience: "Pubblico",
        close: "Chiudi",
        back: "Indietro",
        next: "Procedi",
        save: "Salva",
        publish: "Pubblica",
        leave: "Vuoi davvero tornare alla schermata precedente? Tutte le modifiche andranno perse",
        info: {
            name: "Nome campagna",
            fromName: "Soggetto di Invio",
            fromEmail: "Email di Invio",
            type: "Tipo",
            open: "Aperta",
            closed: "Chiusa",
            multi: "Firma in sequenza",
            expirationDate: "Data di scadenza",
            customLogo: "Logo personalizzato",
            customLogoUpload: "Carica logo",
            customLogoHint: "Dimensioni minime consigliate: 200 x 200 px; Peso massimo file: 2Mb",
            requireOTP: "Richiedi OTP (One Time Password) via SMS",
            placeholders: {
                name: "Inserisci il nome della campagna che sarà visualizzato dai firmatari",
                fromName: "Inserisci il tuo nome o il nome della tua società",
                fromEmail: "Inserisci l'indirizzo email che sarà visualizzato dai firmatari",
            },
            errors: {
                name: "Inserisci il nome della campagna che sarà visualizzato dai firmatari",
                fromName: "Inserisci il tuo nome o il nome della tua società",
                fromEmail: "Inserisci l'indirizzo email che sarà visualizzato dai firmatari",
                customLogo: "Carica un'immagine del logo jpg o png di max 2Mb"
            }
        },
        terms: {
            name: "Nome",
            description: "Descrizione",
            delete: "Elimina",
            add: "Aggiungi termine legale",
            defaultSingleName: "Termine legale",
            defaultName: "Termine legale predefinito",
            defaultDescription: "Dichiaro di aver letto, capito e accetto la documentazione sopra riportata",
            errorDescription: "Inserisci la descrizione del termine legale"
        },
        signers: {
            existentAudience: "Da pubblico esistente",
            newSigners: "Nuovi firmatari",
            choose: "Seleziona pubblico",
            chooseSelectGroup: "Pubblici con numeri di telefono mancanti",
            save: "Salva",
            label: "Firmatari",
            labelDesc: "Aggiungi firmatari o copia/incolla direttamente da un foglio excel",
            fullname: "Nome completo",
            email: "Email",
            phone: "Numero di telefono con prefisso internazionale",
            newSigner: "Inserisci un nuovo firmatario",
            openCampaignDesc: "Pubblicando una campagna aperta verrà generato un URL unico. Chiunque in possesso dell'URL potra vedere e firmare il documento.",
            errors: {
                fullnameNotValid: "E' stato inserito almeno un campo nome completo non valido",
                emailNotValid: "E' stato inserito almeno un campo email non valido",
                emailDuplicate: "E' stato inserito almeno un campo email duplicato",
                phoneNotValid: "E' stato inserito almeno un campo numero di telefono non valido",
                atLeastOneSigner: "Inserisci almeno un firmatario",
            }
        },
        published: {
            proceed: "Pubblicando la tua campagna tutti i contatti nella tua lista riceveranno una email con le istruzioni per accedere al documento. \n Vuoi procedere?",
            title: "Campagna pubblicata",
            message1: "La tua campagna",
            message2: "è stata pubblicata correttamente",
            track: "Puoi tenere traccia degli aggiornamenti o mettere in pausa la campagna nella pagina delle campagne",
            publicURL: "Ecco l'URL pubblico della tua campagna da condividere con i firmatari",
            returnToCampaigns: "Ritorna alle campagne"
        }
    },
    campaignSign: {
        signStyle: "Stile firma",
        graphicOption: "Tratto grafico",
        fontOption: "Firma generata",
        imageOption: "Carica immagine",
        signImageError: "Carica un'immagine png della tua firma, max 2Mb"
    },
    pdfToolbar: {
        zoomIn: "Aumenta Zoom",
        zoomOut: "Riduci Zoom",
        undo: "Annulla",
        redo: "Ripeti",
        createField: "Nuovo campo",
        openSidebar: "Proprietà",
        currentPage: "Numero pagina corrente",
        snap: "Forza allineamento alle guide",
        clone: "Duplica i campi selezionati",
    },
    pdfSidebar: {
        mainTitle: "Proprietà modello",
        fieldsTitle: "Campi",
        signersTitle: "Ordine Firmatari",
        deleteFieldTip: "Elimina campo",
        secondaryTitle: "Proprietà campo",
        fieldName: "Nome del campo",
        requiredError: "Campo obbligatorio",
        fieldNameValidateError: "Caratteri speciali ammessi solo spazio, trattino e parentesi tonde (lunghezza minima 3 caratteri)",
        fieldDescription: "Testo",
        fieldDescriptionValidateError: "Lunghezza minima 3 caratteri",
        fieldType: "Tipo",
        fieldTypeInfo: "Il tipo Numero di telefono non sarà disponibile se il firmatario non ha un numero di telefono associato",
        fieldRequired: "Campo obbligatorio",
        signer: "Assegna firmatario",
        signerInfo: "Un firmatario può non essere disponibile se il tipo di campo è Numero di telefono e il firmatario non ha un numero di telefono associato",
        signerFieldsInfo: "Un firmatario può non essere disponibile se il tipo di campo di uno dei campi selezionato è Numero di telefono e il firmatation non ha un numero di telefono associato",
        deleteFieldConfirmation: "Vuoi davvero eliminare il campo selezionato?",
        deleteFieldsConfirmation: "Vuoi davvero eliminare i campi selezionati?",
    },
    pdfViewer: {
        defaultCreateText: "creating signature field...",
        [TemplateFieldType.EMAIL]: "Email",
        [TemplateFieldType.FULLNAME]: "Nome completo",
        [TemplateFieldType.PHONE]: "Numero di telefono",
        [TemplateFieldType.DATE]: "Data",
        [TemplateFieldType.SIGNATURE]: "Firma",
        [TemplateFieldType.CUSTOM]: "Personalizzato",
        [TemplateFieldType.CONDITIONAL_SIGNATURE]: "Firma condizionale",
        multipleSelection: "Selezione multipla",
        error: {
            errorsFound: "Ci sono errori nel modello:",
            noFields: "Ci deve essere almeno un campo firma presente nel documento",
            fieldsWithNoSigner: "Tutti i campi devono essere assegnati ad un firmatario",
            signerWithNoFields: "Firmatari senza campi firma assegnati (%{signers})",
        }
    },
};
export default it;
