import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, TemplateChoose} from "../components/TemplateChoose";
import {RootState, ThunkDispatch} from "../types";
import {initialize, reset} from "../actions/pdfdoped";
import {doGetCurFile} from "../actions/thunks/campaignCreation";
import {doVerifyPDF} from "../actions/thunks/templates";

function mapStateToProps({campaignCreation, pdfdoped}: RootState): StateProps {
    return {
        fields: pdfdoped.fields,
        pageMatrices: pdfdoped.pageMatrices,
        file: pdfdoped.file,
        documentData: campaignCreation.documentData,
        isSequential: campaignCreation.campaignInfo?.type === "MULTI",
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onInitialize: (file, fields) => dispatch(initialize(file, fields)),
        onReset: () => dispatch(reset()),
        onGetCurFile: (templateId: string) => dispatch(doGetCurFile(templateId)),
        onVerifyPDF: (file) => dispatch(doVerifyPDF(file)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplateChoose);
