import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {
    alertBox,
    KAccordion,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KCheck,
    KContainer,
    KFileUpload,
    KForm,
    KInput,
    KLabel,
    KSelect,
    KSpace, KSpinner, KSpinnerSize,
    KStepProgress
} from "@kopjra/uikit";
import {Template} from "../types/templates";
import {DocumentData} from "../types/campaignCreation";
import {PageMatrix, TemplateField} from "../types/pdfdoped";
import PDFDopedViewer from "../containers/PDFDopedViewer";
import {scrollTop} from "../utils/commons";

export interface StateProps {
    fields?: TemplateField[];
    pageMatrices?: PageMatrix[];
    file?: File;
    documentData?: DocumentData;
    isSequential: boolean;
}

export interface DispatchProps {
    onInitialize: (file: File, fields?: TemplateField[]) => void;
    onReset: () => void;
    onGetCurFile: (templateId: string) => Promise<File | undefined>;
    onVerifyPDF: (file: File) => Promise<boolean>;
}

export interface InnerProps {
    onSubmit: (documentData: DocumentData) => void;
    onBack: (documentData: DocumentData) => void;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const TemplateChoose: React.FC<Props> = ({
            isSequential, fields, file, pageMatrices,
            onInitialize, onReset, documentData, onGetCurFile, onSubmit, onBack, onVerifyPDF
}) => {
    const states = [
        <Translate value={"campaignCreation.campaignInfo"} />,
        <Translate value={"campaignCreation.audience"} />,
        <Translate value={"campaignCreation.document"} />,
    ];

    type TemplateEntry = { title: React.JSX.Element, body: React.JSX.Element };

    const [curSelectedTemplate, setCurSelectedTemplate] = useState<Template | undefined>(documentData?.selectedTemplate);
    const [saveAsNewTemplate, setSaveAsNewTemplate] = useState<boolean>(documentData?.saveAsNew || false);
    const [curTemplateName, setCurTemplateName] = useState<string | undefined>(documentData?.newTemplateName);
    const [curNewChoose, setCurNewChoose] = useState<boolean>(documentData?.newChoose === true ? true : (documentData?.availableTemplates && documentData.availableTemplates.length < 1) ? true : false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [verifyingPDF, setVerifyingPDF] = useState<boolean>(false);


    // TODO: Un'ultima verifica con più campi a testa non solo firma e custom

    useEffect(() => {
        return () => {
            onReset();
        }
    }, [onReset]);

    useEffect(() => {
        if (curSelectedTemplate && documentData?.file) {
            onReset();
            onInitialize(documentData.file, curSelectedTemplate.fields);
        }
    }, [curSelectedTemplate, documentData?.file, onInitialize, onReset]);

    const savedSlot: TemplateEntry = {
        title: <Translate value={"templates.savedTemplate"} />,
        body: <>
            <Row>
                <Col md={2}><KLabel text={<Translate value={"templates.choose"} />} /></Col>
                <Col md={8}>
                    <KSelect key={`chooseTemplate`} required={true} options={(documentData?.availableTemplates || []).map(t => { return { value: t, label: t.name }; })} id={`chooseTemplate`}
                        onChange={async (val) => {
                            const t = val as Template;
                            setCurSelectedTemplate(t);
                            await onGetCurFile(t.id);
                        }}
                        value={curSelectedTemplate} />
                </Col>
                <Col md={2}></Col>
            </Row>
            <Row hidden={!curSelectedTemplate}>
                <Col md={2}><KLabel text={<Translate value={"templates.filename"} />} /></Col>
                <Col className={"text-start"} md={10}><span style={{ fontSize: 12 }}>{curSelectedTemplate?.pdfFilename}</span></Col>
            </Row>
            {documentData?.file ? (
                <>
                    <PDFDopedViewer ignoreSigners={!isSequential} showValidation={showError} onErrors={(e) => setHasError(e)}/>
                    <KSpace spaces={4} />
                    <Row>
                        <Col className={"text-start"}>
                            <KLabel text={<Translate value="templates.saveNewTemplate" />} />
                            <KSpace v={true} spaces={2} />
                            <KCheck id="new" inline label={<Translate value="generic.yes" />} name="newTemplate" required type="radio" defaultChecked={saveAsNewTemplate}
                                onChange={e => e.target.checked ? setSaveAsNewTemplate(true) : undefined}
                            />
                            <KCheck id="notNew" inline label={<Translate value="generic.no" />} name="newTemplate" required type="radio" defaultChecked={!saveAsNewTemplate}
                                onChange={e => e.target.checked ? setSaveAsNewTemplate(false) : undefined}
                            />
                        </Col>
                    </Row>
                    {saveAsNewTemplate ? (
                        <Row><Col md={4}><KInput label={<Translate value={"templates.table.labels.name"} />} id={"newTemplateName"} required onChange={e => setCurTemplateName(e.currentTarget.value)} value={documentData?.newTemplateName} /></Col><Col md={8} /></Row>
                    ) : (
                        <></>
                    )}
                </>
            ) : curSelectedTemplate ? (
                <KSpinner/>
            ) : null}
        </>
    };

    const newSlot: TemplateEntry = {
        title: <Translate value={"templates.newDocument"} />,
        body: <>
            <Row>
                <Col md={2}><KLabel text={<Translate value={"templates.choose"} />} /></Col>
                <Col className={"text-start"} md={10}>
                    {!verifyingPDF ? <KFileUpload showUploadedFilename={true} id={"document"}
                        required={!file}
                        label={<Translate value="templates.upload" />}
                        maxSizeInMb={10}
                                 onChange={async f => {
                                     f = f as File;
                                     if (f.type === "application/pdf") {
                                         setVerifyingPDF(true);
                                         const verified: boolean = await onVerifyPDF(f);
                                         onReset();
                                         if (verified) {
                                             onInitialize(f);
                                         } else {
                                             await alertBox({message: I18n.t("templates.pdfVerifyError")});
                                         }
                                         setVerifyingPDF(false);
                                     }
                                 }}
                        accept={"application/pdf"}
                        valueFilename={file?.name || documentData?.file?.name}
                        requiredErrorMessage={<Translate value={"templates.pdfErrorRequired"} />}
                        maxSizeErrorMessage={<Translate value={"templates.pdfErrorSize"} />}
                    /> : <KSpinner size={KSpinnerSize.nm} />}
                </Col>
            </Row>
            <div hidden={!file}>
                <PDFDopedViewer ignoreSigners={!isSequential} showValidation={showError} onErrors={(e) => setHasError(e)}/>
                <KSpace spaces={4} />
                <Row>
                    <Col className={"text-start"}>
                        <KLabel text={<Translate value="templates.saveNewTemplate" />} />
                        <KSpace v={true} spaces={2} />
                        <KCheck id="new" inline label={<Translate value="generic.yes" />} name="newTemplate" required type="radio" defaultChecked={saveAsNewTemplate}
                            onChange={e => e.target.checked ? setSaveAsNewTemplate(true) : undefined}
                        />
                        <KCheck id="notNew" inline label={<Translate value="generic.no" />} name="newTemplate" required type="radio" defaultChecked={!saveAsNewTemplate}
                            onChange={e => e.target.checked ? setSaveAsNewTemplate(false) : undefined}
                        />
                    </Col>
                </Row>
                {saveAsNewTemplate ? (
                    <Row><Col md={4}><KInput label={<Translate value={"templates.table.labels.name"} />} id={"newTemplateName"} required onChange={e => setCurTemplateName(e.currentTarget.value)} value={documentData?.newTemplateName} /></Col><Col md={8} /></Row>
                ) : (
                    <></>
                )}
            </div>
        </>
    };

    function getDocumentData() {
        const newDocumentData: DocumentData = {
            selectedTemplate: curSelectedTemplate,
            fields: fields?.map(f => ({...f, description: f.description?.trim(), name: f.name?.trim()})),
            pageMatrices: pageMatrices,
            saveAsNew: saveAsNewTemplate,
            newTemplateName: curTemplateName,
            availableTemplates: documentData?.availableTemplates || [],
            newChoose: curNewChoose,
            file: file || documentData?.file
        };
        return newDocumentData;
    }

    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={2} states={states} /></Col></Row>
            <Row><Col lg={12} md={12}>
                <KContainer>
                    <KCard header={<Translate value={"campaignCreation.document"} />}>
                        <KForm onSubmit={() => {
                            if (!hasError) {
                                const newDocumentData = getDocumentData();
                                onSubmit(newDocumentData);
                            } else {
                                setShowError(true);
                                scrollTop();
                            }
                        }}>
                            <KAccordion elements={[savedSlot, newSlot]} exclusive
                                onChangeOpened={(indexes: number[]) => {
                                    const isNewChoose = !(indexes[0] === 0);
                                    setCurNewChoose(isNewChoose);
                                    setCurSelectedTemplate(undefined);
                                    setSaveAsNewTemplate(false);
                                    setCurTemplateName(undefined);
                                    setShowError(false);
                                    setHasError(false);
                                    onReset();
                                }}
                                actives={curNewChoose ? [1] : [0]}
                            />
                            <KSpace spaces={4} />
                            <Row>
                                <Col lg={3} md={4} className={"text-start"}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                    text={<><i className="fal fa-chevron-left" />&nbsp;<Translate value={`campaignCreation.back`} /></>}
                                    onClick={() => { onBack(getDocumentData()) }} />
                                </Col>
                                <Col lg={6} md={4} />
                                <Col lg={3} md={4} className={"text-end"}>
                                    <KButton
                                        type={KBUTTON_TYPE.submit}
                                        variant={KBUTTON_VARIANT.success}
                                        fill={true}
                                        text={<><i className="fal fa-chevron-double-right"/>&nbsp;<Translate value={`campaignCreation.publish`}/></>}
                                    />
                                </Col>
                            </Row>
                        </KForm>
                    </KCard>
                </KContainer>
            </Col></Row>
        </>
    )
}
